<template>
  <div class="subprestation-name">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.SubPrestationName"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title" data-test="sel-role-header">
          <breadcrumb
            location="/subprestation-names"
            text="Sous-prestation"
          ></breadcrumb>
          {{ subPrestationName.name }}
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <span>Informations</span>
        </div>
        <div class="panel-block is-block no-border">
          <div class="columns">
            <div class="column is-half" v-if="subPrestationName">
              <text-field
                label="Nom"
                v-model="subPrestationName.name"
                required
              ></text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "subprestation-name",
  props: ["id"],
  computed: {
    ...mapGetters({
      editMode: "App/isEdition",
    }),
  },
  data() {
    return {
      subPrestationName: {},
    };
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      axios.get(`/subprestation-names/${this.id}`).then((r) => {
        this.subPrestationName = r.data;
        this.$store.dispatch("App/setEditing", false);
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/subprestation-names/${this.id}`).then((res) => {
            if (res.data.success) {
              this.$router.replace("/subprestation-names");
            }
          })
      );
    },
    save() {
      return axios
        .put(`/subprestation-names/${this.id}`, this.subPrestationName)
        .then((response) => {
          if (response.data.success) {
            this.fetchData();
          }
        });
    },
    edit() {
      this.$store.dispatch("App/setEditing", true);
    },
    cancel() {
      this.fetchData();
    },
  },
};
</script>

<style lang='scss' scoped>
</style>